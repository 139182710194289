<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
      KNOTS you should know
      </div>


    </v-app-bar>

    <v-main>
      <Stepper/>
    </v-main>
  </v-app>
</template>

<script>
import Stepper from './components/Stepper';

export default {
  name: 'App',

  components: {
    Stepper
  },

  data: () => ({
    //
  }),
};
</script>
