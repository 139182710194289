<template>
<div>
    <div class="locale-changer">
        <select v-model="$i18n.locale">
            <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
        </select>
    </div>
    <v-stepper v-model="e6" vertical>

        <v-stepper-step :complete="e6 > 1" step="1" @click="e6 = 1" style="cursor: pointer;">
            {{ $t('step1.title') }}
            <small>{{ $t('step1.variations') }}</small>
        </v-stepper-step>

        <v-stepper-content step="1">

            <v-card color="grey lighten-1" class="mt-5 mb-8">
                <v-row>
                    <v-col v-for="card in $t('step1.vars') " :key="card.id" cols="sm-3">
                        <v-card class="pa-4 ma-4" tile>
                            <v-carousel>
                                <v-carousel-item v-for="(item,i) in card.src" :key="i" :src="getImgUrl(item)"></v-carousel-item>
                            </v-carousel>
                            <v-card-title>{{card.title}}</v-card-title>
                            <v-card-text>
                                {{card.text}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>

            <v-btn color="primary" @click="e6 = 2">
                {{ $t('next-button') }}
            </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 2" step="2" @click="e6 = 2" style="cursor: pointer;">
            {{ $t('step2.title') }}
            <small>{{ $t('step2.variations') }}</small>
        </v-stepper-step>
        <v-stepper-content step="2">
            <v-card color="grey lighten-1" class="mt-5 mb-8">
                <v-row>
                    <v-col v-for="card in $t('step2.vars') " :key="card.id" cols="sm-3">
                        <v-card class="pa-4 ma-4" tile>
                            <v-img :src="require('../assets/figure8.jpeg')" />
                            <v-card-title>{{card.title}}</v-card-title>
                            <v-card-text>
                                {{card.text}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-btn color="primary" @click="e6 = 3">
                {{ $t('next-button') }}
            </v-btn>
            <v-btn text @click="e6 = 1">
                {{ $t('previous-button') }}
            </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 3" step="3" @click="e6 = 3" style="cursor: pointer;">
            {{ $t('step3.title') }}
            <small>{{ $t('step3.variations') }}</small>
        </v-stepper-step>
        <v-stepper-content step="3">
            <v-card color="grey lighten-1" class="mt-5 mb-8">
                <v-row>
                    <v-col v-for="card in $t('step3.vars') " :key="card.id" cols="sm-3">
                        <v-card class="pa-4 ma-4" tile>
                            <v-img :src="require('../assets/fisherman.jpg')" />
                            <v-card-title>{{card.title}}</v-card-title>
                            <v-card-text>
                                {{card.text}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-btn color="primary" @click="e6 = 4">
                {{ $t('next-button') }}
            </v-btn>
            <v-btn text @click="e6 = 2">
                {{ $t('previous-button') }}
            </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 4" step="4" @click="e6 = 4" style="cursor: pointer;">
            {{ $t('step4.title') }}
            <small>{{ $t('step4.variations') }}</small>
        </v-stepper-step>
        <v-stepper-content step="4">
            <v-card color="grey lighten-1" class="mt-5 mb-8">
                <v-row>
                    <v-col v-for="card in $t('step4.vars') " :key="card.id" cols="sm-3">
                        <v-card class="pa-4 ma-4" tile>
                            <v-carousel>
                                <v-carousel-item v-for="(item,i) in card.src" :key="i" :src="getImgUrl(item)"></v-carousel-item>
                            </v-carousel>
                            <v-card-title>{{card.title}}</v-card-title>
                            <v-card-text>
                                {{card.text}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-btn color="primary" @click="e6 = 5">
                {{ $t('next-button') }}
            </v-btn>
            <v-btn text @click="e6 = 3">
                {{ $t('previous-button') }}
            </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 5" step="5" @click="e6 = 5" style="cursor: pointer;">
            {{ $t('step5.title') }}
            <small>{{ $t('step5.variations') }}</small>
        </v-stepper-step>
        <v-stepper-content step="5">
            <v-card color="grey lighten-1" class="mt-5 mb-8">
                <v-row>
                    <v-col v-for="card in $t('step5.vars') " :key="card.id" cols="sm-3">
                        <v-card class="pa-4 ma-4" tile>
                            <v-img :src="require('../assets/baatmannsknop.jpeg')" />
                            <v-card-title>{{card.title}}</v-card-title>
                            <v-card-text>
                                {{card.text}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-btn color="primary" @click="e6 = 6">
                {{ $t('next-button') }}
            </v-btn>
            <v-btn text @click="e6 = 4">
                {{ $t('previous-button') }}
            </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 6" step="6" @click="e6 = 6" style="cursor: pointer;">
            {{ $t('step6.title') }}
            <small>{{ $t('step6.variations') }}</small>
        </v-stepper-step>
        <v-stepper-content step="6">
            <v-card color="grey lighten-1" class="mt-5 mb-8">
                <v-row>
                    <v-col v-for="card in $t('step6.vars') " :key="card.id" cols="sm-3">
                        <v-card class="pa-4 ma-4" tile>
                            <v-img :src="require('../assets/stopper.jpg')" />
                            <v-card-title>{{card.title}}</v-card-title>
                            <v-card-text>
                                {{card.text}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-btn color="primary" @click="e6 = 7">
                {{ $t('next-button') }}
            </v-btn>
            <v-btn text @click="e6 = 5">
                {{ $t('previous-button') }}
            </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 7" step="7" @click="e6 = 7" style="cursor: pointer;">
            {{ $t('step7.title') }}
            <small>{{ $t('step7.variations') }}</small>
        </v-stepper-step>
        <v-stepper-content step="7">
            <v-card color="grey lighten-1" class="mt-5 mb-8">
                <v-row>
                    <v-col v-for="card in $t('step7.vars') " :key="card.id" cols="sm-3">
                        <v-card class="pa-4 ma-4" tile>
                            <v-img :src="require('../assets/prusik.jpg')" />
                            <v-card-title>{{card.title}}</v-card-title>
                            <v-card-text>
                                {{card.text}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-btn color="primary" @click="e6 = 8">
                {{ $t('next-button') }}
            </v-btn>
            <v-btn text @click="e6 = 6">
                {{ $t('previous-button') }}
            </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 8" step="8" @click="e6 = 8" style="cursor: pointer;">
            {{ $t('step8.title') }}
            <small>{{ $t('step8.variations') }}</small>
        </v-stepper-step>
        <v-stepper-content step="8">
            <v-card color="grey lighten-1" class="mt-5 mb-8">
                <v-row>
                    <v-col v-for="card in $t('step8.vars') " :key="card.id" cols="sm-3">
                        <v-card class="pa-4 ma-4" tile>
                            <v-img :src="require('../assets/taut.jpeg')" />
                            <v-card-title>{{card.title}}</v-card-title>
                            <v-card-text>
                                {{card.text}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-btn color="primary" @click="e6 = 9">
                {{ $t('next-button') }}
            </v-btn>
            <v-btn text @click="e6 = 7">
                {{ $t('previous-button') }}
            </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 9" step="9" @click="e6 = 9" style="cursor: pointer;">
            {{ $t('step9.title') }}
            <small>{{ $t('step9.variations') }}</small>
        </v-stepper-step>
        <v-stepper-content step="9">
            <v-card color="grey lighten-1" class="mt-5 mb-8">
                <v-row>
                    <v-col v-for="card in $t('step9.vars') " :key="card.id" cols="sm-3">
                        <v-card class="pa-4 ma-4" tile>
                            <v-img :src="require('../assets/clove hitch.jpg')" />
                            <v-card-title>{{card.title}}</v-card-title>
                            <v-card-text>
                                {{card.text}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-btn color="primary" @click="e6 = 10">
                {{ $t('next-button') }}
            </v-btn>
            <v-btn text @click="e6 = 8">
                {{ $t('previous-button') }}
            </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 10" step="10" @click="e6 = 10" style="cursor: pointer;">
            {{ $t('step10.title') }}
            <small>{{ $t('step10.variations') }}</small>
        </v-stepper-step>
        <v-stepper-content step="10">
            <v-card color="grey lighten-1" class="mt-5 mb-8">
                <v-row>
                    <v-col v-for="card in $t('step10.vars') " :key="card.id" cols="sm-3">
                        <v-card class="pa-4 ma-4" tile>
                            <v-img :src="require('../assets/canadian.jpg')" />
                            <v-card-title>{{card.title}}</v-card-title>
                            <v-card-text>
                                {{card.text}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-btn color="primary" @click="e6 = 11">
                {{ $t('next-button') }}
            </v-btn>
            <v-btn text @click="e6 = 9">
                {{ $t('previous-button') }}
            </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 11" step="11" @click="e6 = 11" style="cursor: pointer;">
            {{ $t('step11.title') }}
            <small>{{ $t('step11.variations') }}</small>
        </v-stepper-step>
        <v-stepper-content step="11">
            <v-card color="grey lighten-1" class="mt-5 mb-8">
                <v-row>
                    <v-col v-for="card in $t('step11.vars') " :key="card.id" cols="sm-3">
                        <v-card class="pa-4 ma-4" tile>
                            <v-img :src="require('../assets/rolling.jpg')" />
                            <v-card-title>{{card.title}}</v-card-title>
                            <v-card-text>
                                {{card.text}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-btn color="primary" @click="e6 = 12">
                {{ $t('next-button') }}
            </v-btn>
            <v-btn text @click="e6 = 10">
                {{ $t('previous-button') }}
            </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 12" step="12" @click="e6 = 12" style="cursor: pointer;">
            {{ $t('step12.title') }}
            <small>{{ $t('step12.variations') }}</small>
        </v-stepper-step>
        <v-stepper-content step="12">
            <v-card color="grey lighten-1" class="mt-5 mb-8">
                <v-row>
                    <v-col v-for="card in $t('step12.vars') " :key="card.id" cols="sm-3">
                        <v-card class="pa-4 ma-4" tile>
                            <v-img :src="require('../assets/butterfly.jpg')" />
                            <v-card-title>{{card.title}}</v-card-title>
                            <v-card-text>
                                {{card.text}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-btn color="primary" @click="e6 = 13">
                {{ $t('next-button') }}
            </v-btn>
            <v-btn text @click="e6 = 11">
                {{ $t('previous-button') }}
            </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 13" step="13" @click="e6 = 13" style="cursor: pointer;">
            {{ $t('step13.title') }}
            <small>{{ $t('step13.variations') }}</small>
        </v-stepper-step>
        <v-stepper-content step="13">
            <v-card color="grey lighten-1" class="mt-5 mb-8">
                <v-row>
                    <v-col v-for="card in $t('step13.vars') " :key="card.id" cols="sm-3">
                        <v-card class="pa-4 ma-4" tile>
                            <v-img :src="require('../assets/cleat.jpg')" />
                            <v-card-title>{{card.title}}</v-card-title>
                            <v-card-text>
                                {{card.text}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-btn color="primary" @click="e6 = 14">
                {{ $t('next-button') }}
            </v-btn>
            <v-btn text @click="e6 = 12">
                {{ $t('previous-button') }}
            </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 14" step="14" @click="e6 = 14" style="cursor: pointer;">
            {{ $t('step14.title') }}
            <small>{{ $t('step14.variations') }}</small>
        </v-stepper-step>
        <v-stepper-content step="14">
            <v-card color="grey lighten-1" class="mt-5 mb-8">
                <v-row>
                    <v-col v-for="card in $t('step14.vars') " :key="card.id" cols="sm-3">
                        <v-card class="pa-4 ma-4" tile>
                            <v-img :src="require('../assets/truckers.png')" />
                            <v-card-title>{{card.title}}</v-card-title>
                            <v-card-text>
                                {{card.text}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-btn color="primary" @click="e6 = 15">
                {{ $t('next-button') }}
            </v-btn>
            <v-btn text @click="e6 = 13">
                {{ $t('previous-button') }}
            </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 15" step="15" @click="e6 = 15" style="cursor: pointer;">
            {{ $t('step15.title') }}
            <small>{{ $t('step15.variations') }}</small>
        </v-stepper-step>
        <v-stepper-content step="15">
            <v-card color="grey lighten-1" class="mt-5 mb-8">
                <v-row>
                    <v-col v-for="card in $t('step15.vars') " :key="card.id" cols="sm-3">
                        <v-card class="pa-4 ma-4" tile>
                            <v-img :src="require('../assets/truckers.png')" />
                            <v-card-title>{{card.title}}</v-card-title>
                            <v-card-text>
                                {{card.text}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-btn color="primary" @click="e6 = 16">
                {{ $t('next-button') }}
            </v-btn>
            <v-btn text @click="e6 = 14">
                {{ $t('previous-button') }}
            </v-btn>
        </v-stepper-content>

        <v-stepper-step step="16" @click="e6 = 16" style="cursor: pointer;">
            {{ $t('step15.title') }}
            <small>{{ $t('step15.variations') }}</small>
        </v-stepper-step>
        <v-stepper-content step="16">
            <v-card color="grey lighten-1" class="mt-5 mb-8">
                <v-row>
                    <v-col v-for="card in $t('step16.vars') " :key="card.id" cols="sm-3">
                        <v-card class="pa-4 ma-4" tile>
                            <v-img :src="require('../assets/tripod.jpg')" />
                            <v-card-title>{{card.title}}</v-card-title>
                            <v-card-text>
                                {{card.text}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-btn color="primary" @click="e6 = 1">
                {{ $t('next-button') }}
            </v-btn>
            <v-btn text @click="e6 = 15">
                {{ $t('previous-button') }}
            </v-btn>
        </v-stepper-content>

    </v-stepper>
</div>
</template>

<script>
export default {
    name: 'locale-changer',
    data() {
        return {
            e6: 1,
            langs: ['no', 'en'],
            model: 0,
            colors: [
                'primary',
                'secondary',
                'yellow darken-2',
                'red',
                'orange',
            ],
            items: [{
                    src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
                },
                {
                    src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
                },
                {
                    src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
                },
                {
                    src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
                },
            ],
        }
    },
    methods: {
        getImgUrl(pic) {
            return require('../assets/' + pic)
        }
    },

}
</script>
<i18n>
{
    "no": {
        "next-button": "Neste",
        "previous-button": "Forrige",
        "step1": {
            "title": "Pålestikk",
            "variations": "3 variasjoner",
            "vars":[{"title": "Dobbel pålestikk", "text": "Ved klatring benyttes dobbel pålestikk. Ofte også med en enkel fiskeknute som backup", "src": ["pålestikk_double_ferdig.jpg", "pålestikk_double1.jpg", "pålestikk_double2.jpg", "pålestikk_double3.jpg", "pålestikk_double4.jpg"]},
                    {"title": "Tredd pålestikk", "text": "Knyttes etter at man har tredd tauet gjennom en ring. feks ved fortøying av båt", "src": ["pålestikk_tredd_ferdig.jpg", "pålestikk_tredd_1.jpg", "pålestikk_tredd_2.jpg", "pålestikk_tredd_3.jpg", "pålestikk_tredd_4.jpg", "pålestikk_tredd_5.jpg", "pålestikk_tredd_6.jpg", "pålestikk_tredd_7.jpg"]},
                    {"title": "Enkel pålestikk", "text": "For å lage en løkke på enden av et tau", "src": ["pålestikk_ferdig.jpg","pålestikk1.jpg", "pålestikk2.jpg", "pålestikk3.jpg"]}]
        },
        "step2": {
            "title": "8 talls knute",
            "variations": "3 variasjoner",
            "vars":[{"title": "Tredd åttetallsknut", "text": "For innbinding i klatresele"},
                    {"title": "Slått 8 talls knut", "text": "Brukes f.eks til å lage standplass med coredellette eller når man skal snu på et topptau fest"},
                    {"title": "Dobbelt løkke", "text": "Litt som slått 8 tallsknute, men gir 2 løkker. Fin til å lage topptau feste"}]
        },
        "step3": {
            "title": "Fiskeknute",
            "variations": "3 variasjoner",
            "vars":[{"title": "Enkel fiskeknute", "text": "Brukes ofte som ekstra backup på knuter"},
                    {"title": "Dobbel fiskeknute", "text": "For å skjøte 2 tau til prusik løkke. Eller hvis gjort parrallell skjøte 2 tau for rappellering"},
                    {"title": "Trippel fiskeknute", "text": "Samme bruksområdene som dobbel fiskeknute, men enda sikrer."}]
        },
        "step4": {
            "title": "Flagg stikk",
            "variations": "2 variasjoner",
            "vars":[{"title": "Enkel flagstikk", "text": "Skjøting av 2 tau. Ikke til klatring. Hvis forskjellige størrelse på tauene surr den lille rundt den store.", "src": ["flaggstikk_ferdig.jpg","flaggstikk1.jpg", "flaggstikk2.jpg", "flaggstikk3.jpg"]},
                {"title": "Dobbel flagstikk", "text": "Samme bruksområdet som enkel flaggstikk, men sikrere", "src": ["flaggstikk_double_ferdig.jpg","flaggstikk1.jpg", "flaggstikk2.jpg", "flaggstikk3.jpg", "flaggstikk_double_1.jpg", "flaggstikk_double_2.jpg"]}]
        },
        "step5": {
            "title": "Båtmannsknop",
            "variations": "1 variasjoner",
            "vars":[{"title": "Båtmannsknop", "text": "For å skjøte 2 tau."}]
        },
        "step6": {
            "title": "Stopper knot",
            "variations": "1 variasjoner",
            "vars":[{"title": "Stopper knute", "text": "Benyttes for å lage en stopper knut på enden av tauet. Feks til klatring."}]
        },
        "step7": {
            "title": "Prusik, Autoblock (Rappellering), Vikingknute",
            "variations": "3 variasjoner",
            "vars":[{"title": "Prusik", "text": "låser under spenning. For knutegang på tau"},
                    {"title": "Vikingknute", "text": "Samme bruksområde som prusik"},
                    {"title": "Autoblock", "text": "Backupknut for rappellering"}]
        },
        "step8": {
            "title": "Teltstikk (Taut line)",
            "variations": "1 variasjoner",
            "vars":[{"title": "Teltstikk", "text": "regulerbar knut fin til stramming av barduner"}]
        },
        "step9": {
            "title": "Halvstikk (Clove hitch)",
            "variations": "3 variasjoner",
            "vars":[{"title": "Dobbel halvstikk (rundt påle)", "text": "Innbinding av fendere. lett å regulere"},
                    {"title": "Dobbel halvstikk (over påle)", "text": "Vanlig å binde ting fast til stolpe med f.eks båt"},
                    {"title": "Dobbel halvstikk (over karabiner)", "text": "For innbinding på standplass i klatring"}]
        },
        "step10": {
            "title": "Canadian Jam knot",
            "variations": "2 variasjoner",
            "vars":[{"title": "Slått", "text": "lager løkke som man kan ha liggeunderlag eller liknende i. Låser ved stramming. Enkel å løsne"},
                    {"title": "Tredd", "text": "Bindes rundt en påle eller tre. Fin start knut for surring av tau"}]
        },
        "step11": {
            "title": "Rolling hitch (Magnus hitch)",
            "variations": "1 variasjoner",
            "vars":[{"title": "Rolling hitch", "text": "Rask knute for å stramme rundt et tau. Feks for å løsne et annet tau som har satt seg fast i seiling"}]
        },
        "step12": {
            "title": "Butterfly hitch (Alpine hitch)",
            "variations": "1 variasjoner",
            "vars":[{"title": "Butterfly hitch", "text": "Lager løkke midt i et tau som ikke strammer seg ved belastning. Mye brukt i alpine formål"}]
        },
        "step13": {
            "title": "Cleat hitch",
            "variations": "1 variasjoner",
            "vars":[{"title": "Cleat hitch", "text": "For å feste et tau rundt cleat. Typisk bt knute"}]
        },
        "step14": {
            "title": "Truckers hitch",
            "variations": "1 variasjoner",
            "vars":[{"title": "Truckers hitch", "text": "Lager en stram line for eksempel til å henge tarp over eller feste ting over en tilhenger"}]
        },
        "step15": {
            "title": "Timber hitch",
            "variations": "1 variasjoner",
            "vars":[{"title": "Timber hitch", "text": "Start knut for surringer. Også fin til å dra stokker med"}]
        },
        "step16": {
            "title": "Surring av tripod eller liknende",
            "variations": "2 variasjoner",
            "vars":[{"title": "2 pinner", "text": "for å lage en ramme eller liknende"},
                    {"title": "tripod", "text": "Enkelt telt eller som del i en mer robust struktur"}]
        }
    },
    "en": {
        "next-button": "Next",
        "previous-button": "Previous",
        "step1": {
            "title": "Bowline",
            "variations": "3 Variastions",
            "vars":[{"title": "Double bowline", "text": "Ved klatring benyttes dobbel pålestikk. Ofte også med en enkel fiskeknute som backup"},
                    {"title": "Threaded bowline", "text": "Knyttes etter at man har tredd tauet gjennom en ring"},
                    {"title": "Single bowline", "text": "For å lage en løkke på enden av et tau"}]
        }
    }
}
</i18n>
